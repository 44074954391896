import { StatusBar } from "expo-status-bar";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Platform, StyleSheet } from "react-native";
import { ActivityIndicator, Colors } from "react-native-paper";
import EditScreenInfo from "../../components/EditScreenInfo";
import { Text, View } from "../../components/Themed";
import { BackHandler } from "react-native";
import { useState } from "react";

export default function LoadingScreen({ navigation }) {
  const [finishedLoading, setFinishedLoading] = useState(false);
  const auth = getAuth();
  console.log(finishedLoading);

  onAuthStateChanged(auth, async (user) => {
    console.log(user);

    if (finishedLoading) return;

    if (user) {
      navigation.navigate("GrowListScreen");
      setFinishedLoading(true);
      return;
    }

    setFinishedLoading(true);
    navigation.navigate("Auth");
  });

  return (
    <View style={styles.container}>
      <ActivityIndicator animating={true} color={Colors.red800} size="large" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: "80%",
  },
});
