import { StyleSheet, BackHandler, View } from "react-native";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Fab from "../components/GrowListing/Fab";
import MainHeaderBar from "../components/Nav/MainHeaderBar";
import GrowList from "../components/GrowListing/GrowList";

const queryClient = new QueryClient();

export default function GrowListScreen({ navigation }) {
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      () => true
    );
    return () => backHandler.remove();
  }, []);

  return (
    <View style={styles.container}>      
      <QueryClientProvider client={queryClient} >
        <MainHeaderBar navigation={navigation}></MainHeaderBar>        
        <GrowList navigation={navigation} />
        <Fab navigation={navigation}></Fab>
      </QueryClientProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#263238",
  },
});

