import * as React from "react";
import { FAB, Portal, Provider } from "react-native-paper";
import { StyleSheet } from "react-native";

export default function Fab({ navigation }) {
  const [state, setState] = React.useState({ open: false });

  const onStateChange = ({ open }) => setState({ open });

  const { open } = state;

  return (
    <Provider>
      <Portal>
        <FAB.Group
          open={open}
          icon={open ? "close" : "plus"}
          fabStyle={styles.fab}
          actions={[
            {
              icon: "plus",
              label: "Add Grow",
              onPress: () => navigation.navigate("Camera"),
            },
          ]}
          onStateChange={onStateChange}
          onPress={() => {
            if (open) {
              // do something if the speed dial is open
            }
          }}
        />
      </Portal>
    </Provider>
  );
}

const styles = StyleSheet.create({
  fab: {
    backgroundColor: "#ffb300",
  },
});
