import { StyleSheet, Image } from "react-native";
import { Button } from "react-native-paper";
import { Text, View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import AuthHeaderBar from "../../components/Nav/AuthHeaderBar";

export default function WelcomeScreen({ navigation }) {
  return (
    <View style={styles.container}>
      <Image
        source={require("../../assets/images/logo.png")}
        style={styles.image}
      ></Image>
      <Button
        style={styles.input}
        mode="contained"
        onPress={() => navigation.navigate("SignIn")}
      >
        Sign In
      </Button>
      <Button
        style={styles.input}
        mode="contained"
        onPress={() => navigation.navigate("SignUp")}
      >
        Sign Up
      </Button>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    padding: 20,
    backgroundColor: "#263238",
  },
  input: {
    margin: 5,
    padding: 10,
  },
  image: {
    width: 300,
    alignSelf: "center",
  },
});
