import React, { useState, useEffect, useRef } from "react";
import { StyleSheet, Text, View, TouchableOpacity, Image } from "react-native";
import { Camera, CameraType } from "expo-camera";
import { useQuery } from "react-query";
import axios from "axios";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import * as FileSystem from "expo-file-system";
import * as Analytics from "expo-firebase-analytics";
import { Entypo } from "@expo/vector-icons";

export default function App({ growId }) {
  console.log(growId);
  const auth = getAuth();
  const [user, setUser] = useState(auth.currentUser);

  // Handle token updates
  onAuthStateChanged(auth, async (user) => {
    setUser(user);
    Analytics.logEvent("user_token", {
      name: `token updated for user token - ${await user?.getIdToken()}`,
    });
  });

  // State
  const [hasPermission, setHasPermission] = useState(false);
  const [type, setType] = useState(CameraType.back);
  const [isCapturing, setIsCapturing] = useState(false);
  const ref = useRef(null);

  // Constants
  const CAMERA_CAPTURE_INTERVAL = 300000;
  const API_URL = "https://mygrownow.com/api/images";

  if (!user) return <View>User is not signed in</View>;

  const takePhoto = async () => {
    try {
      const photo = await ref.current.takePictureAsync();
      const file = await FileSystem.readAsStringAsync(photo.uri, {
        encoding: FileSystem.EncodingType.Base64,
      });
      const { data } = await axios.post(
        API_URL,
        {
          image: file,
          growId: growId,
        },
        { headers: { Authentication: await user.getIdToken() } }
      );
      Analytics.logEvent("image_upload", {
        name: `image uploaded - response data => ${data}`,
      });
      console.log("image uploaded");
    } catch (ex) {
      console.log(ex);
    }
  };

  const startCapture = async () => {
    setIsCapturing(true);
    await takePhoto();
    setTimeout(async () => {
      await startCapture();
    }, CAMERA_CAPTURE_INTERVAL);
  };

  const stopCapture = async () => {
    //setIsCapturing(false);
    Analytics.logEvent("image_upload", { name: `stopping image capture` });
  };

  useEffect(() => {
    (async () => {
      const { status } = await Camera.requestCameraPermissionsAsync();
      setHasPermission(status === "granted");
      Analytics.logEvent("user_permission", {
        name: `camera permission prompted - response => success: ${hasPermission}`,
      });
    })();
  }, []);

  if (hasPermission === null) {
    return <View />;
  }
  if (hasPermission === false) {
    return <Text>No access to camera</Text>;
  }

  return (
    <View style={styles.container}>
      <Camera style={styles.camera} type={type} ref={ref}>
        <View style={styles.buttonContainer}>
          <TouchableOpacity
            onPress={isCapturing ? stopCapture : startCapture}
            style={styles.button}
          >
            <Image
              source={
                isCapturing
                  ? require("../assets/images/stop-button.png")
                  : require("../assets/images/record.png")
              }
              style={styles.buttonImage}
            />
          </TouchableOpacity>
          <TouchableOpacity onPress={takePhoto} style={styles.cameraButton}>
            <Entypo name="camera" size={24} color="white" style={styles.icon} />
          </TouchableOpacity>
        </View>
      </Camera>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  camera: {
    flex: 1,
  },
  buttonContainer: {
    flex: 1,
    backgroundColor: "transparent",
    flexDirection: "row",
    margin: 20,
  },
  button: {
    flex: 1,
    alignSelf: "flex-end",
    alignItems: "center",
    width: 50,
  },
  cameraButton: {
    position: "absolute",
    bottom: 40,
    right: 50,
    width: 50,
  },
  buttonImage: {
    width: 100,
    height: 100,
  },
  text: {
    fontSize: 18,
    color: "white",
  },
});
