import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import { StyleSheet } from "react-native";
import { Headline, Button } from "react-native-paper";
import { QueryClient } from "react-query";
import AuthHeaderBar from "../../components/Nav/AuthHeaderBar";
import { Text, View } from "../../components/Themed";
import { RootTabScreenProps } from "../../types";
import { TextInput } from "react-native-paper";

const queryClient = new QueryClient();

export default function SignInScreen({
  navigation,
}: RootTabScreenProps<"Images">) {
  const auth = getAuth();
  const [email, SetEmail] = useState("");
  const [password, SetPassword] = useState("");
  const [loading, SetLoading] = useState(false);

  return (
    <View style={styles.container}>
      <AuthHeaderBar navigation={navigation}></AuthHeaderBar>
      <View style={styles.content}>
        <Headline>Sign In</Headline>
        <TextInput
          style={styles.input}
          onChangeText={(text) => SetEmail(text)}
          placeholder="Email Adress"
        />
        <TextInput
          style={styles.input}
          onChangeText={(text) => SetPassword(text)}
          secureTextEntry={true}
          placeholder="Password"
        />
        <Button
          style={styles.submit}
          mode="contained"
          loading={loading}
          compact={false}
          onPress={async () => {
            try {
              SetLoading(true);
              await signInWithEmailAndPassword(auth, email, password);
              navigation.navigate("EntryListScreen");
            } catch (ex) {
              SetLoading(false);
            }
          }}
        >
          Sign In
        </Button>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    backgroundColor: "#263238",
  },
  content: {
    flex: 1,
    margin: 25,
    justifyContent: "center",
    backgroundColor: "#263238",
  },
  input: {
    margin: 5,
  },
  submit: {
    margin: 5,
    padding: 10,
  },
});
