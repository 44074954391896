import * as React from "react";
import { Appbar, Button, Divider, Menu } from "react-native-paper";
import { StyleSheet, Text } from "react-native";
import { getAuth, signOut } from "firebase/auth";

export default function MainHeaderBar({ navigation }) {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const auth = getAuth();

  return (
    <Appbar.Header>
      <Appbar.Content title="My Grow Now" style={{ alignItems: 'center', paddingLeft: 50 }}/>
      <Appbar.Action icon="dots-vertical" onPress={openMenu} />
      <Menu visible={visible} onDismiss={closeMenu} anchor={<Text> </Text>}>
        <Menu.Item
          onPress={() => {
            signOut(auth);
            closeMenu();
          }}
          title="Sign Out"
        />
      </Menu>
    </Appbar.Header>
  );
}
