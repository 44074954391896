import { useKeepAwake } from 'expo-keep-awake';
import React, { useEffect } from 'react';
import { StyleSheet } from 'react-native';
import { QueryClient, QueryClientProvider } from 'react-query';

import Camera from '../../components/Camera';
import AuthHeaderBar from '../../components/Nav/AuthHeaderBar';
import { Text, View } from '../../components/Themed';
import { RootTabScreenProps } from '../../types';

const queryClient = new QueryClient();

export default function CameraScreen({ navigation, route }) {
  const { growId } = route.params;
  useKeepAwake();
  return (
    <QueryClientProvider client={queryClient}>
      <AuthHeaderBar navigation={navigation}></AuthHeaderBar>
      <Camera growId={growId}/>
    </QueryClientProvider>
  );
}