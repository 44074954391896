import * as React from "react";
import { Appbar, Button, Divider, Menu } from "react-native-paper";
import { StyleSheet, Text } from "react-native";
import { getAuth, signOut } from "firebase/auth";

export default function AuthHeaderBar({ navigation: { goBack } }) {
  const [visible, setVisible] = React.useState(false);
  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);
  const auth = getAuth();

  return (
    <Appbar.Header>
      <Appbar.BackAction
        onPress={() => {
          goBack();
        }}
      />
      <Appbar.Content title="My Grow Now" />
    </Appbar.Header>
  );
}
