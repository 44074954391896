import { format } from "date-fns";
import * as React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  Linking,
} from "react-native";
import { Headline } from "react-native-paper";
import FlowerLabel from "./FlowerLabel";

export default function EntryCard({ navigation, item }) {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() =>
        navigation.navigate("ImageListScreen", { entryId: item.id })
      }
    >
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={{ uri: item.imageUrl }} />
        <View style={styles.dayBlock}>
          <Text style={styles.textDay}>
            day {item.day}
          </Text>
        </View>
        <View style={styles.textBlock}>
          <FlowerLabel item={item}></FlowerLabel>
          <Text style={styles.text}>
            {format(new Date(item.date._seconds * 1000), "MMMM do, yyyy")}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  imageContainer: {
    flex: 1,
    marginBottom: 0,
    position: "relative",
  },
  image: {
    aspectRatio: 1,
    justifyContent: "space-around",
    borderRadius: 5,
    overflow: "hidden",
    marginBottom: 5,
    opacity: 0.9,
    backgroundColor: "#000",
  },
  text: {
    color: "#fff",
    fontSize: 24,
  },
  textBlock: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: 15,
  },
  dayBlock: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: 15,
    padding: 5,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: "#43a047",
    borderRadius: 5
  },
  textDay: {
    color: "#fff",
    fontSize: 18,
  },
});
