import * as React from "react";
import {
  View,
  StyleSheet,
  Text,
} from "react-native";
import { Entypo } from '@expo/vector-icons'; 

export default function FlowerLabel({ item }) {
  console.log(item.flowerWeek)
  if (item.flowerWeek) {
    return (
      <View style={styles.imageContainer}>
        <Entypo name="flower" size={24} color="green" style={styles.icon}/>
        <Text style={styles.text}>
         Week {item.flowerWeek} 
        </Text>
      </View>
    );
  }

  return <View></View>;
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  imageContainer: {
    flex: 1,
    flexDirection: "row",
    marginBottom: 0,
    position: "relative",
    justifyContent: "space-around"
  }, 
  text: {
    color: "#fff",
    position: "absolute",
    bottom: 0,
    right: 0,
    fontSize: 18,
    padding: 5,
  },
  icon: {
    padding: 2,
  },
});
