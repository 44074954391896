import { StyleSheet, BackHandler, View } from "react-native";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import EntryList from "../components/EntryListing/EntryList";
import { RootTabScreenProps } from "../types";
import Fab from "../components/EntryListing/Fab";
import MainHeaderBar from "../components/Nav/MainHeaderBar";
import AuthHeaderBar from "../components/Nav/AuthHeaderBar";

const queryClient = new QueryClient();

export default function EntryListScreen({ navigation, route }) {
  const { growId, growName } = route.params;
  useEffect(() => {
    const backHandler = BackHandler.addEventListener(
      "hardwareBackPress",
      () => true
    );
    return () => backHandler.remove();
  }, []);

  return (
    <View style={styles.container}>
      <QueryClientProvider client={queryClient}>
        <AuthHeaderBar navigation={navigation}></AuthHeaderBar>
        <EntryList navigation={navigation} growId={growId} growName={growName} />
        <Fab navigation={navigation} growId={growId}></Fab>
      </QueryClientProvider>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#263238",
  },
});

