import { Text, TextProps, View } from "../Themed";
import { FlatList, StyleSheet } from "react-native";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import ActionButton from "react-native-action-button";
import { RootTabScreenProps } from "../../types";
import React from "react";
import { ActivityIndicator, Colors } from "react-native-paper";
import EntryCard from "./ImageCard";
import ImageCard from "./ImageCard";

export default function ImageList({ navigation, entryId, growName }) {
  const auth = getAuth();
  const user = auth.currentUser;

  if (!user) return <View>User is not signed in</View>;

  const { isLoading, error, data } = useQuery("imageData", async () => {
    const { data } = await axios.get(
      `https://mygrownow.com/api/images/${entryId}?page=0&size=12`,
      {
        headers: {
          authentication: await user.getIdToken(),
        },
      }
    );

    return data;
  });

  if (isLoading)
    return (
      <View style={styles.loader}>
        <ActivityIndicator
          style={styles.loaderIcon}
          animating={true}
          color={Colors.yellow500}
          size="large"
        />
      </View>
    );

  return (
    <View style={styles.container}>
      <Text style={styles.text}>{growName} Grow Captures</Text>
      <FlatList
        data={data.images}
        renderItem={({ item }) => (
          <ImageCard navigation={navigation} item={item}></ImageCard>
        )}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#263238",
  },
  loader: {
    backgroundColor: "#263238",
    padding: 30,
    alignContent: "center",
  },
  loaderIcon: {
    alignSelf: "center",
  },
  text: {
    color: "#fff",
    fontSize: 24,
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 10
  },
});
