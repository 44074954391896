import { format } from "date-fns";
import * as React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  Linking,
} from "react-native";
import { Headline } from "react-native-paper";

export default function ImageCard({ navigation, item }) {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() => {
        Linking.openURL(item.url);
      }}
    >
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={{ uri: item.url }} />
        <Text style={styles.text}>
          {format(new Date(item.date._seconds * 1000), "MMMM do, yyyy H:mma")}
        </Text>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  imageContainer: {
    flex: 1,
    marginBottom: 0,
    position: "relative",
  },
  image: {
    aspectRatio: 1,
    justifyContent: "space-around",
    borderRadius: 5,
    overflow: "hidden",
    marginBottom: 5,
    opacity: 0.9,
    backgroundColor: "#000",
  },
  text: {
    color: "#fff",
    position: "absolute",
    bottom: 0,
    right: 0,
    fontSize: 24,
    padding: 15,
  },
});
