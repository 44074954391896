import { format } from "date-fns";
import * as React from "react";
import {
  View,
  StyleSheet,
  TouchableOpacity,
  Image,
  Text,
  Linking,
} from "react-native";
import { Headline } from "react-native-paper";

export default function GrowCard({ navigation, item }) {
  return (
    <TouchableOpacity
      style={styles.container}
      onPress={() =>
        navigation.navigate("EntryListScreen", { growId: item.id, growName: item.name })
      }
    >
      <View style={styles.imageContainer}>
        <Image style={styles.image} source={{ uri: item.imageUrl }} />        
        <View style={styles.textBlock}>
          <Text style={styles.text}>
            {item.name}
          </Text>
          <Text style={styles.dateText}>
            {format(new Date(item.date._seconds * 1000), "MMMM do, yyyy")}
          </Text>
        </View>
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    margin: 5,
  },
  imageContainer: {
    flex: 1,
    marginBottom: 0,
    position: "relative",
    height: 200,
    overflow: "hidden"
  },
  image: {
    aspectRatio: 1,
    justifyContent: "space-around",
    borderRadius: 5,
    overflow: "hidden",
    marginBottom: 5,
    opacity: 0.8,
    backgroundColor: "#000",
  },
  text: {
    color: "#fff",
    fontSize: 32,
  },
  dateText: {
    color: "#fff",
    fontSize: 24,
    textAlign: "right"
  },
  textBlock: {
    position: "absolute",
    bottom: 0,
    right: 0,
    padding: 25,
    textAlign: "right"    
  },
});
