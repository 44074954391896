import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { initializeApp } from "firebase/app";
import { SafeAreaView, StyleSheet } from "react-native";
import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import AuthScreen from "./screens/auth/AuthScreen";
import SignInScreen from "./screens/auth/SignInScreen";
import SignUpScreen from "./screens/auth/SignUpScreen";
import EntryListScreen from "./screens/EntryListScreen";
import GrowListScreen from "./screens/GrowListScreen";
import CameraScreen from "./screens/camera/CameraScreen";
import LoadingScreen from "./screens/auth/LoadingScreen";
import {
  DarkTheme as PaperDarkTheme,
  DefaultTheme as PaperDefaultTheme,
  Provider as PaperProvider,
} from "react-native-paper";
import MainHeaderBar from "./components/Nav/MainHeaderBar";
import ImageListScreen from "./screens/ImageListScreen";

const firebaseConfig = {
  apiKey: "AIzaSyC0U8eQrZNwrADLPh9u5_fMOuZqGH1r10Q",
  projectId: "mygrownow",
  authDomain: "mygrownow.firebaseapp.com",
};

initializeApp(firebaseConfig);

export default function App() {
  const isLoadingComplete = useCachedResources();
  const colorScheme = useColorScheme();
  const Stack = createNativeStackNavigator();

  const theme = {
    ...PaperDarkTheme,
    roundness: 2,
    colors: {
      ...PaperDarkTheme.colors,
      primary: "#000a12",
      accent: "#fb8c00",
    },
  };

  return (
    <SafeAreaProvider>
      <SafeAreaView style={styles.container}>
        <StatusBar style="light" />
        <PaperProvider theme={theme}>
          <NavigationContainer>
            <Stack.Navigator
              screenOptions={{
                headerShown: false,
              }}
            >
              <Stack.Screen name="Home" component={LoadingScreen} />
              <Stack.Screen name="Auth" component={AuthScreen} />
              <Stack.Screen name="SignIn" component={SignInScreen} />
              <Stack.Screen name="SignUp" component={SignUpScreen} />
              <Stack.Screen
                name="EntryListScreen"
                component={EntryListScreen}
              />
              <Stack.Screen name="GrowListScreen" component={GrowListScreen} />
              <Stack.Screen
                name="ImageListScreen"
                component={ImageListScreen}
              />
              <Stack.Screen name="Camera" component={CameraScreen} />
            </Stack.Navigator>
          </NavigationContainer>
        </PaperProvider>
      </SafeAreaView>
    </SafeAreaProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#000",
    flex: 1,
    color: "#fff",
  },
  text: {
    fontSize: 25,
    fontWeight: "500",
  },
});
